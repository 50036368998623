<template>
  <div>
    <div class="text-center mb-3">
      <v-menu
        open-on-click
        right-center
        offset-y
        v-for="(menu, index) in menus"
        :key="index"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!menu.link"
            color="default"
            v-bind="attrs"
            v-on="on"
            class="mr-3 wSame"
          >
            {{ $vuetify.lang.t(menu.title) }}
          </v-btn>
          <v-btn v-else color="default" :to="menu.link" class="mr-3 wSame">
            {{ $vuetify.lang.t(menu.title) }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, idx) in menu.nested"
            :key="idx"
            link
            :to="item.link"
          >
            <v-list-item-title>{{
              $vuetify.lang.t(item.title)
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider class="mb-3" />
    <router-view></router-view>
  </div>
</template>

<script>
import Auth from "@/helpers/authentication";
export default {
  data: function () {
    return {
      perm: Auth.isAuthenticated()["permission"],
      menus: [
        {
          title: "$vuetify.manager.manage_pages",
          link: "/manager/site/pagelist",
        },
        {
          title: "$vuetify.manager.manage_menus",
          linkActive: "/manager/site/menu",
          nested: [
            {
              title: "$vuetify.manager.menu_main",
              link: "/manager/site/menu/main",
            },
            {
              title: "$vuetify.manager.menu_bottom",
              link: "/manager/site/menu/bottom",
            },
          ],
        },
        {
          title: "$vuetify.manager.manage_slider",
          link: "/manager/site/banners",
        },
        {
          title: "$vuetify.manager.manage_news",
          link: "/manager/site/news",
        },
        {
          title: "$vuetify.manager.site_files",
          link: "/manager/site/files",
        },
        {
          title: "$vuetify.manager.site_settings",
          link: "/manager/site/settings",
        },
      ],
    };
  },
};
</script>
<style lang="css" scoped>
.wSame {
  width: 120px;
  margin-bottom: 5px;
}
</style>
