<template>
    <NavigationList/>
</template>
<script>
import NavigationList from "@/components/manager/site/Navigation";
export default {
    components: {
        NavigationList
    },
}
</script>